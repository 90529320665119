<template>
	<div class="row">
		<div class="col-lg-12 col-xxl-12 order-5 order-xxl-5">
			<TicketsList />
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	components: {
		TicketsList: () => import('@/view/content/widgets/dashboard/TicketsList.vue'),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Tickets' }]);
	},
};
</script>

<style>
</style>